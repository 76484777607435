import React from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import * as JStorageActions from 'rev.sdk.js/Actions/JStorage';
import * as AppActions from '../../../AppActions';
import * as Ant from 'antd';
import qs from 'query-string';
import {ProductItem, VideoItem, ArticleItem} from './NbProductItem';

function getQueryParams() {
  if (typeof window !== 'undefined' && window.location) {
    return qs.parse(window.location.search);
  }
  return {};
}

export default function ProductGrid(props) {
  const [user] = useOutlet('user');
  const {onItemClick, prefixPath} = props;
  const [products, setProducts] = React.useState(props.products);
  const [dimension] = useOutlet('dimension');
  const [productTotal, setProductTotal] = React.useState(0);
  const pageSize = 12;
  const params = getQueryParams();
  const currPage = parseInt(params.page, 10) || 1;
  const productOffset = (currPage - 1) * pageSize;

  let cat = '';
  let search = '';
  if (params) {
    cat = params.cat;
    search = params.search;
  }

  const fetchProductPage = React.useCallback(
    async ({offset}) => {
      // NOTICE: race condition between our component and SDK's ProductListPage (both will show and hide global spinner)
      let isLoading = false;
      setTimeout(() => {
        AppActions.setLoading(true);
        isLoading = true;
      }, 100);

      let allowLevels = user.data.levels || [];

      const catQuery = cat ? {labels: {$regex: cat}} : {};
      let allQueries = {};
      allQueries = {
        ...catQuery,
        public: true,
        // model: search,
        levels: {$in: allowLevels},
        $or: [
          {model: {$regex: search || '', $options: 'i'}},
          {name: {$regex: search || ''}},
          {description: {$regex: search || ''}},
        ],
      };

      let {
        results: _products,
        total,
      } = await JStorageActions.fetchDocuments(
        'product',
        allQueries,
        ['type', 'priority'],
        {offset, limit: pageSize},
      );

      setProductTotal(total);
      setProducts(_products);

      if (isLoading) {
        AppActions.setLoading(false);
      }

      let raw_images_list = [];

      raw_images_list = _products.map((product) => {
        let _images = Array.isArray(product.images) ? product.images : [];
        let _first_img = _images[0];

        if (!_first_img || _first_img.indexOf('base64') >= 0) {
          return 'empty-img/empty-img.png';
        }

        return `${_first_img}.small`;
      });

      if (raw_images_list.length > 0) {
        raw_images_list = raw_images_list.map((img, idx) => ({
          idx,
          img,
        }));

        const rawImgGroups = [];
        while (raw_images_list.length > 0) {
          rawImgGroups.push(raw_images_list.splice(0, 4));
        }

        const fetchWatermarkedImages = async (rawImgs) => {
          try {
            let watermarkImages = await AppActions.getImagesWithDealerV2(
              rawImgs.map((x) => x.img),
            );

            setProducts((prevProducts) => {
              const nextProducts = [...prevProducts];
              for (let i = 0; i < watermarkImages.length; i++) {
                console.log('idx', rawImgs[i].idx);
                if (nextProducts[rawImgs[i].idx]) {
                  nextProducts[rawImgs[i].idx].images = [watermarkImages[i]];
                }
              }
              return nextProducts;
            });
          } catch (ex) {
            console.warn('fetch watermark-img API fail', ex);
          }
          return true;
        };

        const imgPromises = rawImgGroups.map(fetchWatermarkedImages);
        await Promise.all(imgPromises);
      } else {
        setProducts([]);
      }
    },
    [cat, user, search],
  );

  React.useEffect(() => {
    async function fetchData() {
      if (prefixPath.indexOf('products') >= 0) {
        fetchProductPage({offset: productOffset});
      }
    }

    if (user?.data) {
      fetchData();
    }
  }, [user, prefixPath, fetchProductPage, productOffset]);

  const mobile = dimension.rwd === 'mobile';

  function renderPagination() {
    return (
      <div className="nb-pager" style={{padding: '0 20px'}}>
        <Ant.Pagination
          showSizeChanger={false}
          total={productTotal}
          current={currPage}
          showTotal={(total) => `共 ${total} 筆, 每頁 ${pageSize} 筆`}
          pageSize={pageSize}
          onChange={(page, pageSize) => {
            AppActions.navigate(
              `/products/?cat=${params.cat || ''}&page=${page}` +
                (search ? `&search=${search}` : ''),
            );
          }}
        />
      </div>
    );
  }

  if (prefixPath.indexOf('products') >= 0) {
    return (
      <>
        {renderPagination()}

        <ProductGridWrapper
          mobile={mobile}
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '60vh',
          }}>
          {products.length === 0 ? (
            <div>
              <img src="/images/empty.jpg" width={130} />
              <p style={{marginTop: 20, letterSpacing: 1, textAlign: 'center'}}>
                無相符商品
              </p>
            </div>
          ) : (
            <>
              {products.map((product, i) => (
                <a
                  target="_blank"
                  href={`/product?id=${product.id}`}
                  rel="noreferrer"
                  onClick={(e) => {
                    e.preventDefault();
                    AppActions.navigate(`/product?id=${product.id}`);
                  }}>
                  <ProductItem
                    key={i}
                    mobile={mobile}
                    product={product}
                    // onClick={(evt) => onItemClick(product, evt)} style={{backgroundColor: 'red', padding: 130, margin: 30}}
                  />
                </a>
              ))}
              {[1, 2, 3].map((item) => (
                <ProductItem empty />
              ))}
            </>
          )}
        </ProductGridWrapper>

        {renderPagination()}
      </>
    );
  } else if (prefixPath.indexOf('articles') >= 0) {
    return (
      <ArticleGridWrapper mobile={mobile}>
        {products.map((product, i) => (
          <ArticleItem
            key={i}
            mobile={mobile}
            product={product}
            onClick={(evt) => onItemClick(product, evt)}
          />
        ))}
      </ArticleGridWrapper>
    );
  } else if (prefixPath.indexOf('videos') >= 0) {
    return (
      <ProductGridWrapper mobile={mobile}>
        {props.products
          .sort((a, b) => a.priority - b.priority)
          .map((product, i) => (
            <VideoItem
              key={i}
              mobile={mobile}
              video={product}
              onClick={(evt) => onItemClick(product, evt)}
            />
          ))}
        {[1, 2, 3].map((item) => (
          <VideoItem empty />
        ))}
      </ProductGridWrapper>
    );
  } else {
    return null;
  }
}

const ProductGridWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: ${(props) => (props.mobile ? 'center' : 'space-between')};
  padding: ${(props) => (props.mobile ? 0 : 'var(--basePadding)')};
`;

const ArticleGridWrapper = styled.div`
  align-self: ${(props) => (props.mobile ? 'center' : 'space-between')};
  padding: ${(props) => (props.mobile ? 0 : 'var(--basePadding)')};
`;
